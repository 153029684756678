import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { type SEOHandle } from '@nasa-gcn/remix-seo'
import { Card, Divider } from '@tremor/react'
import { data, Form, Link, useSearchParams } from 'react-router'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { z } from 'zod'

import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { CheckboxField, ErrorList, Field } from '#app/components/forms.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { login, requireAnonymous } from '#app/utils/auth.server.ts'
import {
	ProviderConnectionForm,
	providerNames,
} from '#app/utils/connections.tsx'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { useIsPending } from '#app/utils/misc.tsx'
import { PasswordSchema, EmailSchema } from '#app/utils/user-validation.ts'

import { type Route } from './+types/login.ts'
import { handleNewSession } from './login.server.ts'

export const handle: SEOHandle = {
	getSitemapEntries: () => null,
}

const LoginFormSchema = z.object({
	email: EmailSchema,
	password: PasswordSchema,
	redirectTo: z.string().optional(),
	remember: z.boolean().optional(),
})

export async function loader({ request }: Route.LoaderArgs) {
	await requireAnonymous(request)
	return {}
}

export async function action({ request }: Route.ActionArgs) {
	await requireAnonymous(request)
	const formData = await request.formData()
	await checkHoneypot(formData)
	const submission = await parseWithZod(formData, {
		schema: (intent) =>
			LoginFormSchema.transform(async (data, ctx) => {
				if (intent !== null) return { ...data, session: null }

				const session = await login({ username: data.email, ...data })
				if (!session) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Invalid password',
					})
					return z.NEVER
				}

				return { ...data, session }
			}),
		async: true,
	})

	if (submission.status !== 'success' || !submission.value.session) {
		return data(
			{ result: submission.reply({ hideFields: ['password'] }) },
			{ status: submission.status === 'error' ? 400 : 200 },
		)
	}

	const { session, remember, redirectTo } = submission.value

	return handleNewSession({
		request,
		session,
		remember: remember ?? false,
		redirectTo,
	})
}

export default function LoginPage({ actionData }: Route.ComponentProps) {
	return (
		<div className="flex flex-col gap-6">
			<div className="flex flex-col items-center gap-1.5 text-center">
				<h1 className="text-2xl font-bold">Welcome back</h1>
				<p className="text-sm text-muted-foreground">
					Log in to your Intermave account
				</p>
			</div>

			<OauthStrategies />

			<Divider className="-mb-3 mt-0 text-[0.6rem] uppercase tracking-wide text-muted-foreground/80">
				Or continue with
			</Divider>

			<EmailPasswordForm actionData={actionData} />

			<SignUpLink />
		</div>
	)
}

function OauthStrategies() {
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')

	return (
		<ul className="flex flex-col gap-3">
			{providerNames.map((providerName) => (
				<li key={providerName}>
					<ProviderConnectionForm
						type="Log in"
						providerName={providerName}
						redirectTo={redirectTo}
					/>
				</li>
			))}
		</ul>
	)
}

function EmailPasswordForm({
	actionData,
}: {
	actionData: Route.ComponentProps['actionData']
}) {
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')
	const isPending = useIsPending()
	const [form, fields] = useForm({
		id: 'login-form',
		constraint: getZodConstraint(LoginFormSchema),
		defaultValue: { redirectTo },
		lastResult: actionData?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: LoginFormSchema })
		},
		shouldRevalidate: 'onBlur',
	})

	return (
		<Form method="POST" {...getFormProps(form)}>
			<fieldset disabled={isPending} className="flex flex-col gap-3">
				<HoneypotInputs />
				<Field
					labelProps={{ children: 'Email' }}
					inputProps={{
						...getInputProps(fields.email, { type: 'email' }),
						autoFocus: true,
						className: 'lowercase',
						autoComplete: 'email',
						required: true,
					}}
					errors={fields.email.errors}
				/>

				<Field
					labelProps={{
						children: (
							<div className="flex items-center justify-between">
								<div>Password</div>
								<Link
									to="/forgot-password"
									className="text-xs underline-offset-2 hover:underline"
								>
									Forgot password?
								</Link>
							</div>
						),
					}}
					inputProps={{
						...getInputProps(fields.password, {
							type: 'password',
						}),
						autoComplete: 'current-password',
						required: true,
					}}
					errors={fields.password.errors}
				/>

				<div className="flex justify-between">
					<CheckboxField
						labelProps={{
							htmlFor: fields.remember.id,
							children: 'Remember me',
						}}
						buttonProps={getInputProps(fields.remember, {
							type: 'checkbox',
						})}
						errors={fields.remember.errors}
					/>
				</div>

				<input {...getInputProps(fields.redirectTo, { type: 'hidden' })} />
				<ErrorList errors={form.errors} id={form.errorId} />

				<Button className="w-full" type="submit">
					Log in
				</Button>
			</fieldset>
		</Form>
	)
}

function SignUpLink() {
	return (
		<div className="text-center text-sm">
			Don&apos;t have an account?{' '}
			<Link to="/signup" className="underline underline-offset-2">
				Sign up
			</Link>
		</div>
	)
}

export const meta: Route.MetaFunction = () => {
	return [{ title: 'Login to Intermave' }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
